import { Injectable } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker'
import { ApiService } from '../services/api.service';
import {ENDPOINTS} from './../app.config';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService{

  readonly VAPID_PUBLIC_KEY = "BECXeIA8gjqveHeZP3r67maMUiWIL-662wTOR1AoU6uWuz3Hmq19ZLnWmnMttHo8TcB6m3o23Dz1Av9fyYzYn50";


  constructor(
    private swPush: SwPush,
    private swUpdate:SwUpdate,
    private apiService: ApiService,
  ) {
    this.swUpdate.available.subscribe(event=>{
      console.log("e",event)
      this.swUpdate.activateUpdate().then(()=>document.location.reload())
    })
    this.subscribeNotification()
   }

   flag=false
  

  subscribeNotification(){
    this.swPush.notificationClicks.subscribe((notification:any)=>{
      //console.log("chk",notification)
      if(!this.flag){
        //window.open(notification.data.url)
        window.open(notification.data.url,'_self')
        this.flag=true
      }
    })
    this.flag=false

    this.swPush.requestSubscription({serverPublicKey:this.VAPID_PUBLIC_KEY})
    .then(pushSubsription=>{
      console.log(pushSubsription.toJSON())
    })
  }

  sendNotification(e:any){
    console.log("Notification");
    //this.ngOnInit();
    this.subscribeNotification()
    this.pushSubsription(e);
  }

  pushSubsription(e:any){
    console.log("Push Notification");

    this.swPush.requestSubscription(
      {
        serverPublicKey:this.VAPID_PUBLIC_KEY
      }
    ).then((res)=>{
      console.log(res);
      if(e=='event'){
        this.apiService.postApiCall(ENDPOINTS.EVENTNOTIFICATION, res).subscribe()
      }
      else if(e=='member'){
        this.apiService.postApiCall(ENDPOINTS.MEMBERNOTIFICATION, res).subscribe()
      }
      else if(e=='chat'){
        this.apiService.postApiCall(ENDPOINTS.CHATNOTIFICATION, res).subscribe()
      }
  })
}

}

